import { Box, Button, Card, H3, H4, Icon, ModalDialog, ModalDialogBody, ModalDialogCloseButton, ModalDialogContent, ModalDialogNav, Typography } from '@vp/swan'
import classNames from 'classnames'
import { useLocalization } from 'hooks/use-localization'
import { useMsgAlternateTextMailingList } from 'lib/intl/msg-alternate-texts.hooks'
import { useMsgMailingListExampleLabel } from 'lib/intl/msg-mailing-list-actions.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { useUploadSampleContext } from 'modules/upload/contexts/upload-sample.context'
import { FC, useMemo, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import Markdown from 'markdown-to-jsx'

import downloadTemplateImageEnUs from './download-template-en-us.svg'
import downloadTemplateImageEsUs from './download-template-es-us.svg'
import downloadTemplateImageEnCa from './download-template-en-ca.svg'
import downloadTemplateImageFrCa from './download-template-fr-ca.svg'

import { SITE_LOCALES, TOP_DOMAINS } from 'constants/locale.constants'
import { getTopDomain } from 'lib/site-links/site-link.utils'
import classes from './upload-sample.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

import { useDownloadTemplate } from '../../../../hooks/use-download-template'

export const UploadSample: FC<unknown> = () => {
  const { openSample, setOpenSample } = useUploadSampleContext()
  const isPostcard = usePostcardPRDCheck()
  const mailingListExampleLabel = useMsgMailingListExampleLabel()
  const alternateTextMailingList = useMsgAlternateTextMailingList()
  const zoomInSample = () => setOpenSample(true)
  const modalCloseLabel = useMsgModalClose()
  const { locale } = useLocalization()
  const topDomain = getTopDomain(locale)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [isEndOfScroll, setIsEndOfScroll] = useState<boolean>(false)
  const downloadTemplate = useDownloadTemplate()

  const downloadTemplateImage = useMemo(() => {
    if (topDomain === TOP_DOMAINS.CA) return locale === SITE_LOCALES.FR_CA ? downloadTemplateImageFrCa : downloadTemplateImageEnCa
    return locale === SITE_LOCALES.ES_US ? downloadTemplateImageEsUs : downloadTemplateImageEnUs
  }, [locale, topDomain])

  const intl = useIntl()
  const zoomButtonAriaLabel = intl.formatMessage({
    defaultMessage: 'View large image of the address upload template file',
    description: 'Visually hidden text for viewing large template image',
  })

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const recipientInstructions = intl.formatMessage({
    defaultMessage: `Your list should follow this format, including every column header. <br/>
    Remember, fill either **Recipient** or **Company**. The rest of the fields are required. <br/>
    The **Company** field can also be used for a secondary recipient.`,
  })

  const alternativeUploadSampleContainer = !loadingManualAddressingFlag
    ? manualAddressingFeatureToggle
      ? intl.formatMessage({
          defaultMessage: 'Click to view the complete example of the recipient list required format and extra information',
          description: 'Alt message for the box that contains the image for the required recipient list format',
        })
      : intl.formatMessage({
          defaultMessage: 'Click to view the complete example of the address list required format and extra information',
          description: 'Alt message for the box that contains the image for the required address list format',
        })
    : ''

  const onScrollImageHandler = () => {
    if (imageContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = imageContainerRef.current
      if (scrollLeft + clientWidth === scrollWidth) {
        setIsEndOfScroll(true)
        return
      }

      setIsEndOfScroll(false)
    }
  }

  return (
    <>
      <Box role="button" onClick={zoomInSample} className={classNames(classes.uploadSampleContainer)} alt={alternativeUploadSampleContainer}>
        <Card bordered backgroundColor="standard" className={classes.uploadSample}>
          {isPostcard ? (
            <H4 mb={{ lg: 6, md: 5, xs: 4 }}>
              <FormattedMessage defaultMessage="Required mailing list format" />
            </H4>
          ) : (
            <H4 mb={{ lg: 6, md: 5, xs: 4 }}>
              {!loadingManualAddressingFlag ? (
                manualAddressingFeatureToggle ? (
                  <FormattedMessage defaultMessage="Required recipient list format" />
                ) : (
                  <FormattedMessage defaultMessage="Example address List" />
                )
              ) : (
                ''
              )}
            </H4>
          )}

          <div className={isEndOfScroll ? classes.scrollContainerNoFade : classes.scrollContainer} onScroll={onScrollImageHandler} ref={imageContainerRef}>
            <img src={downloadTemplateImage} alt={alternateTextMailingList} />
          </div>
        </Card>
        <Button aria-label={zoomButtonAriaLabel} skin="primary" onClick={zoomInSample} className={classes.zoomBtn} buttonShape="round">
          <Icon iconType="zoomIn" />
        </Button>
      </Box>
      <ModalDialog isOpen={openSample} onRequestDismiss={() => setOpenSample(false)}>
        <ModalDialogContent className={classes.zoomedContent} aria-label={mailingListExampleLabel}>
          <ModalDialogNav>
            <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
          </ModalDialogNav>
          <ModalDialogBody>
            <Box className={classes.metaContent}>
              <H3 fontSize={'x2large'} mt={{ xs: 6, sm: 0 }} mb={{ xs: 5, sm: 4 }}>
                {!loadingManualAddressingFlag ? (
                  isPostcard ? (
                    <FormattedMessage defaultMessage="Mailing list example" description="Heading for a sample Mailing list" />
                  ) : manualAddressingFeatureToggle ? (
                    <FormattedMessage defaultMessage="Required recipient list format" description="Heading for a sample recipient list format" />
                  ) : (
                    <FormattedMessage defaultMessage="Address list example" description="Heading for a sample Address list" />
                  )
                ) : (
                  ''
                )}
              </H3>
              <Box mb={6} className={classes.subTextContainer}>
                <Typography fontSize={'small'} mr={{ sm: 0, md: 7 }} className={classes.subText}>
                  {isPostcard ? (
                    <FormattedMessage
                      defaultMessage="*Required as per USPS guidelines: First name, last name and/or company name, address, city, state, and Zip code."
                      description="Information for a valid mailing list"
                    />
                  ) : (
                    <Markdown>{recipientInstructions}</Markdown>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.uploadImgContainer}>
              <img className={classes.uploadImg} src={downloadTemplateImage} alt={alternateTextMailingList} />
            </Box>
            <Button iconPosition="right" mt={5} className={classes.downloadBtn} onClick={downloadTemplate}>
              <Icon className={classes.icon} iconType="download" />
              <FormattedMessage defaultMessage="Download template" description="Button label to download template for the mailing list" />
            </Button>
          </ModalDialogBody>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}
