import { useCallback } from 'react'
import { generateAndDownloadTheTemplateFrom, getMLTemplateFileNameFrom } from '../modules/upload/components/upload-sample/download-ml-template'
import { useAccessToken } from '../lib/user'
import { useLocalization } from './use-localization'
import { PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from '../lib/telemetry/tracking.types'
import { getMailingListTemplateBuffer } from '../lib/address-list/address-book.api'
import { useTrackingProductPageName } from './use-product.hook'
import { useEventTracking } from '../lib/telemetry/tracking.hooks'

export function useDownloadTemplate() {
  const { locale } = useLocalization()
  const accessToken = useAccessToken() as string
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const fireTracking = useEventTracking()

  const downloadTemplate = useCallback(async () => {
    const trackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.DOWNLOAD_TEMPLATE,
      pageName: trackingProductPageName,
    }

    fireTracking(TrackingEventType.LINK_CLICKED, trackingData)

    const mlTemplateResponse = await getMailingListTemplateBuffer({ locale, accessToken })
    const buffer = mlTemplateResponse.data
    const fileName = getMLTemplateFileNameFrom(mlTemplateResponse)
    generateAndDownloadTheTemplateFrom({ buffer, fileName })
  }, [trackingProductPageName, fireTracking, locale, accessToken])

  return downloadTemplate
}
