import { useIntl } from 'react-intl'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

export function useMsgCompletedListsLabel() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Completed lists',
    description: 'Heading for showing the mailing lists that are in completed state',
  })
}

export function useMsgInProgressLabel() {
  const intl = useIntl()
  return intl.formatMessage({ defaultMessage: 'In progress', description: 'Status shown when the mailing list is in loading state' })
}

export function useMsgMailingListLabel() {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  if (loadingManualAddressingFlag) {
    return ''
  }

  return isPostcard
    ? intl.formatMessage({ defaultMessage: 'Mailing lists', description: 'Heading for Mailing lists' })
    : manualAddressingFeatureToggle
    ? intl.formatMessage({ defaultMessage: 'Recipient lists', description: 'Heading for Recipient lists' })
    : intl.formatMessage({ defaultMessage: 'Address lists', description: 'Heading for Address lists' })
}

export function useMsgMailingListExampleLabel() {
  const intl = useIntl()
  const isPostcard = usePostcardPRDCheck()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  if (loadingManualAddressingFlag) {
    return ''
  }

  return isPostcard
    ? intl.formatMessage({
        defaultMessage: 'Mailing list example',
        description: 'Caption for sample Mailing list',
      })
    : manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage: 'Recipient list example',
        description: 'Caption for sample Recipient list',
      })
    : intl.formatMessage({
        defaultMessage: 'Address list example',
        description: 'Caption for sample Address list',
      })
}

export function useMsgCreateMailingList() {
  const intl = useIntl()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  if (loadingManualAddressingFlag) {
    return ''
  }

  return manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage: 'Recipient list successfully created',
        description: 'Recipient list created confirmation',
      })
    : intl.formatMessage({
        defaultMessage: 'Address list successfully created',
        description: 'Address list created confirmation',
      })
}

export function useMsgCreateYourList() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Create your list',
    description: 'Text for create list',
  })
}

export function useMsgUploadYourFile() {
  const intl = useIntl()
  return intl.formatMessage({
    defaultMessage: 'Upload your file',
    description: 'Text for upload file',
  })
}
