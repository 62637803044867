import { Box, Button, Column, FlexBox, GridContainer, H3, Icon, List, ListItem, Row, Typography, useScreenClass } from '@vp/swan'
import { useNavigateToReview } from 'hooks/navigate.hooks'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { PageLayout } from 'layouts/page/page.layout'
import { useAddressListQuery, useAddressListUpload } from 'lib/address-list'
import { withErrorBoundary } from 'lib/errors'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { PageName } from 'lib/telemetry'
import { PageNameForLogging } from 'lib/telemetry/tracking.types'
import { useUploadPageStatus } from 'modules/upload/contexts/upload-status.context'
import { UploadPageStatus } from 'modules/upload/types/upload-status.type'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { BtnMailingList } from '../btn-mailing-list/btn-mailing-list.component'
import { BtnUpload } from '../btn-upload/btn-upload.component'
import { UploadFooter } from '../upload-footer/upload-footer.component'
import { UploadSample } from '../upload-sample/upload-sample.component'
import { UploadStatus } from '../upload-status/upload-status.component'
import layoutClasses from '../../../../layouts/page/page-layout.module.scss'
import classes from './upload-container.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'
import { useDownloadTemplate } from '../../../../hooks/use-download-template'

function arrLenSum(...arrays: (unknown[] | undefined | null)[]): number {
  return arrays.reduce((sum, array) => sum + (array?.length || 0), 0)
}

const UploadContainerInternal: FC<unknown> = () => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const { uploadPageStatus, setUploadPageStatus } = useUploadPageStatus()
  const [file, setFile] = useState<File>()
  const [fileNameNavbar, setFileNameNavbar] = useState<string>()
  const { data: requestId, isLoading: isUploading, isError: isErrorUploading } = useAddressListUpload(file)
  const {
    data: addressList,
    isLoading: isProcessing,
    isError: isErrorProcessing,
    isSuccess: isSuccessProcessing,
  } = useAddressListQuery({ requestId, retryCount: 6 })

  const navigateToReview = useNavigateToReview()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const screenClass = useScreenClass()
  const downloadTemplate = useDownloadTemplate()
  const { logError, logWarn } = useLogger()

  useEffect(() => {
    // reset everything on file change
    setUploadPageStatus(UploadPageStatus.INITIAL)
  }, [file, setUploadPageStatus])
  useEffect(() => {
    if (isUploading) {
      setUploadPageStatus(UploadPageStatus.UPLOADING)
    } else if (isErrorUploading) {
      setUploadPageStatus(UploadPageStatus.ERROR_UPLOADING_FAILED)
      logError('Error while uploading the file', { contextData: { requestId, status: UploadPageStatus.ERROR_UPLOADING_FAILED } })
    } else if (isProcessing) {
      setUploadPageStatus(UploadPageStatus.PROCESSING)
    } else if (isErrorProcessing) {
      setUploadPageStatus(UploadPageStatus.ERROR_PROCESSING_FAILED)
      logError('Error while processing the uploaded file', { contextData: { requestId, status: UploadPageStatus.ERROR_PROCESSING_FAILED } })
    } else if (isSuccessProcessing) {
      setUploadPageStatus(UploadPageStatus.SUCCESS)
    }
  }, [isUploading, isErrorUploading, isProcessing, isErrorProcessing, isSuccessProcessing, setUploadPageStatus, requestId, logError])
  useEffect(() => {
    if (
      requestId &&
      isSuccessProcessing &&
      uploadPageStatus !== UploadPageStatus.ERROR_INVALID_FILE_NAME &&
      uploadPageStatus !== UploadPageStatus.ERROR_INVALID_FILE_TYPE
    ) {
      const totalAddressCount = arrLenSum(addressList?.validAddresses, (addressList?.duplicatedAddressGroups || []).flat(), addressList?.invalidAddresses)
      if (totalAddressCount) {
        navigateToReview(requestId)
      } else {
        setUploadPageStatus(UploadPageStatus.ERROR_EMPTY_FILE)
        logWarn('The uploaded file is empty', { contextData: { requestId, status: UploadPageStatus.ERROR_EMPTY_FILE } })
      }
    }
  }, [addressList, navigateToReview, setUploadPageStatus, requestId, isSuccessProcessing, uploadPageStatus, logWarn])

  return (
    <PageLayout pageName={trackingProductPageName} pageNameForLogging={PageNameForLogging.UPLOAD_PAGE} fileName={fileNameNavbar}>
      <Box className={`${classes.container} ${layoutClasses.fullHeight}`}>
        <GridContainer>
          <Row>
            <Column span={6} spanSm={12}>
              <UploadStatus fileNameNavbar={fileNameNavbar} />
            </Column>
          </Row>
          <Row pt={8}>
            <Column span={6} spanSm={12}>
              <GridContainer>
                <Row>
                  <Column span={10} spanSm={12}>
                    <H3 fontSize={'x2large'} mb={{ md: 5, xs: 4 }} fontWeight="bold">
                      {!loadingManualAddressingFlag && (
                        <>
                          {manualAddressingFeatureToggle ? (
                            <FormattedMessage defaultMessage="Upload your recipient list." description="Heading for uploading a new recipient list" />
                          ) : (
                            <FormattedMessage defaultMessage="Add your address list." description="Heading for adding a new address list" />
                          )}
                        </>
                      )}
                    </H3>
                    <Typography fontSize={'standard'} mb={3}>
                      <List skin="standard" px={4}>
                        <ListItem my={0} py={0}>
                          <FormattedMessage defaultMessage="Use the required recipient list format." />
                        </ListItem>
                        <ListItem my={0} py={0}>
                          <FormattedMessage defaultMessage="Column headers are required." />
                        </ListItem>
                        <ListItem my={0} py={0}>
                          <FormattedMessage defaultMessage="Accepted file types: .xls .xlsx .csv." />
                        </ListItem>
                      </List>
                    </Typography>
                    <Typography fontSize={'standard'} mb={3}>
                      <FormattedMessage defaultMessage="More questions? Help is here: 1.855.210.1085" />
                    </Typography>
                    <Typography id="format-help" fontSize={'small'} textColor="subtle">
                      <FormattedMessage defaultMessage="Final price based on the quantity." />
                    </Typography>
                  </Column>
                </Row>
                <Row pt={{ md: 6, xs: 5 }}>
                  <Column span={7} spanMd={8} spanSm={12}>
                    <BtnUpload className={classes.actionBtn} setFile={setFile} setFileNameNavbar={setFileNameNavbar} />
                    {!loadingManualAddressingFlag &&
                      (manualAddressingFeatureToggle ? (
                        <Button iconPosition="right" mt={3} specialVariant="design-path" className={classes.actionBtn} onClick={downloadTemplate}>
                          <Icon iconType="download" />
                          <FormattedMessage defaultMessage="Download template" description="Button label to download template for the mailing list" />
                        </Button>
                      ) : (
                        <BtnMailingList className={classes.actionBtn} />
                      ))}
                  </Column>
                </Row>
              </GridContainer>
            </Column>
            <Column mt={{ md: 0, sm: 8, xs: 6 }} span={6} spanSm={12}>
              <UploadSample />
              <Box my={6} px={2}>
                <FlexBox justifyContent="center" alignItems="start">
                  {!loadingManualAddressingFlag && !manualAddressingFeatureToggle && (
                    <Button
                      skin="link"
                      iconPosition={screenClass === 'sm' || screenClass === 'xs' ? 'right' : 'left'}
                      mt={{ xs: 5, sm: 4, md: 0 }}
                      specialVariant={screenClass === 'sm' || screenClass === 'xs' ? 'design-path' : 'standard'}
                      onClick={downloadTemplate}
                    >
                      <Icon mr={{ xs: 0, md: 2 }} ml={{ xs: 2, md: 0 }} iconType="download" />
                      <FormattedMessage defaultMessage="Download template" description="Button label to download template for the mailing list" />
                    </Button>
                  )}
                </FlexBox>
              </Box>
            </Column>
          </Row>
        </GridContainer>
        <UploadFooter />
      </Box>
    </PageLayout>
  )
}

export const UploadContainer = withErrorBoundary(UploadContainerInternal)
