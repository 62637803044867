import { Box, FlexBox, Hidden, Typography, useScreenClass } from '@vp/swan'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { usePrivacyPageURL } from 'lib/site-links'
import { useEventTracking } from 'lib/telemetry'
import { PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType, TrackingNavigationType } from 'lib/telemetry/tracking.types'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import uploadSecure from './secure.svg'
import classes from './upload-footer.module.scss'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const alignByScreenClass: Record<string, { align: 'left' | 'center' }> = {
  xs: { align: 'left' },
  sm: { align: 'left' },
  md: { align: 'center' },
  lg: { align: 'center' },
}

export const UploadFooter: FC<{
  className?: string
}> = ({ className }) => {
  const privacyPolicyPath = usePrivacyPageURL()
  const fireTracking = useEventTracking()
  const screenClass = useScreenClass()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)

  const onSeePrivacy = () => {
    const sourcePage = typeof window !== 'undefined' ? window.location.href : null
    const destinationPage = privacyPolicyPath
    const trackingData = {
      category: TrackingCategoryType.CONFIGURE_MAILING,
      label: TrackingLabelType.PRIVACY_LABEL,
      eventDetail: `${sourcePage};${destinationPage};Content;${TrackingNavigationType.PRIVACY_NAVIGATION}`,
      navigationDetail: TrackingNavigationType.PRIVACY_NAVIGATION,
      pageName: trackingProductPageName,
    }

    fireTracking(TrackingEventType.NAVIGATION_CLICKED, trackingData)
  }
  const Policy: FC<{ policyLinkText: string }> = ({ policyLinkText }) => (
    <a
      className={classes.privacyLink}
      href={privacyPolicyPath}
      target="_blank"
      rel="noopener noreferrer"
      data-section="Mailing List Page:Text Link"
      data-position="0"
      data-translation="See Privacy & Cookie Policy"
      onClick={onSeePrivacy}
    >
      {policyLinkText}
    </a>
  )

  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const intl = useIntl()
  const policyMessage = manualAddressingFeatureToggle
    ? intl.formatMessage({
        defaultMessage: 'VistaPrint securely stores your recipient list. We never collect or sell this information. For more details, please see our ',
      })
    : intl.formatMessage({
        defaultMessage: 'VistaPrint securely stores your address list. We never collect or sell this information. For more details, please see our ',
      })
  const policyLinkText = intl.formatMessage({ defaultMessage: 'Privacy Policy' })
  return (
    <Box mb={4} px={2} className={`${className ?? ''} ${classes.footer}`}>
      <FlexBox justifyContent="center" alignItems="start">
        <Hidden xs sm mr={3}>
          <img src={uploadSecure} alt="" />
        </Hidden>
        <Typography fontSize={'small'} {...alignByScreenClass[screenClass]} textColor="subtle">
          {!loadingManualAddressingFlag && (
            <>
              {policyMessage} <Policy policyLinkText={policyLinkText} />
            </>
          )}
        </Typography>
      </FlexBox>
    </Box>
  )
}
