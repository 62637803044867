import {
  Box,
  Button,
  FlexBox,
  H2,
  Icon,
  ModalDialog,
  ModalDialogBody,
  ModalDialogCloseButton,
  ModalDialogContent,
  ModalDialogFooter,
  SelectionSet,
  SelectionSetInput,
  SelectionSetLabel,
  Spinner,
  Typography,
} from '@vp/swan'
import classNames from 'classnames'
import { Ellipsis } from 'components/ellipsis/ellipsis.component'
import { useQueryParam } from 'contexts/query-param.context'
import { useNavigateToReview } from 'hooks/navigate.hooks'
import { useTrackingProductPageName } from 'hooks/use-product.hook'
import { useSignIn } from 'hooks/use-sign-in.hook'
import { useAddressListInProgress, useMailingListAll } from 'lib/address-list'
import { useMsgCompletedListsLabel, useMsgInProgressLabel, useMsgMailingListLabel } from 'lib/intl/msg-mailing-list-actions.hooks'
import { useMsgModalClose } from 'lib/intl/msg-modal.hooks'
import { newRelicNoticeError } from 'lib/new-relic'
import { useEventTracking } from 'lib/telemetry'
import { AdditionalTrackingDataType, PageName, TrackingCategoryType, TrackingEventType, TrackingLabelType } from 'lib/telemetry/tracking.types'
import { useIsSignedIn } from 'lib/user'
import { useUploadPageStatus } from 'modules/upload/contexts/upload-status.context'
import { UploadPageStatus } from 'modules/upload/types/upload-status.type'
import { FC, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, FormattedRelativeTime, useIntl } from 'react-intl'
import { MailingListEntity } from 'types/mailing-list.types'
import { usePostcardPRDCheck } from 'utilities/functions.utils'
import classes from './btn-mailing-list.module.scss'

import { withErrorBoundary } from 'lib/errors'
import { useLogger } from '@vp/shared-capabilities-component-library/components'
import { useAddToCart } from 'lib/mailing-svc-api'
import { useToastSomethingWentWrong } from 'lib/toast'
import { useFeatureFlagContext } from 'contexts/feature-flag.context'

const ListSelectionSection: FC<{
  sectionHeading: string
  list: {
    id: string
    title: string
    recipientsCount: number
    lastUpdatedOn: number
  }[]
}> = ({ sectionHeading, list }) => {
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  return (
    <Box mb={6}>
      {!!sectionHeading && (
        <Typography mb={3} fontSize={'small'} fontWeight="bold">
          {sectionHeading}
        </Typography>
      )}
      {list.map(u => (
        <SelectionSetInput key={u.id} value={u.id}>
          <SelectionSetLabel>
            <Typography fontSize={'small'} fontWeight="bold">
              <Ellipsis title={u.title} fontSize={'small'} weight="bold" />
            </Typography>
            <Typography fontSize={'xsmall'} my={1}>
              {u.recipientsCount === 1 ? (
                <FormattedMessage
                  defaultMessage="{recipientCount} recipient"
                  description="Shows the number of recipients in a mailing list when there is one recipient e.g. 1 recipient"
                  values={{ recipientCount: u.recipientsCount }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="{recipientCount} recipients"
                  description="Shows the number of recipients in a mailing list when there is more than one recipient e.g. 5 recipients"
                  values={{ recipientCount: u.recipientsCount }}
                />
              )}
            </Typography>
            {!!u.lastUpdatedOn && !loadingManualAddressingFlag && (
              <Typography fontSize={'xsmall'} textColor="subtle">
                <FormattedRelativeTime value={(u.lastUpdatedOn - Date.now()) / 1000} numeric="auto" updateIntervalInSeconds={10}>
                  {(relativeTime: string) =>
                    manualAddressingFeatureToggle ? (
                      <FormattedMessage
                        defaultMessage="Updated {relativeTime}"
                        description="Shows when was the recipient list updated. Eg. Updated 4 mins ago"
                        values={{ relativeTime }}
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Updated {relativeTime}"
                        description="Shows when was the address list updated. Eg. Updated 4 mins ago"
                        values={{ relativeTime }}
                      />
                    )
                  }
                </FormattedRelativeTime>
              </Typography>
            )}
          </SelectionSetLabel>
        </SelectionSetInput>
      ))}
    </Box>
  )
}

const BtnMailingListInternal: FC<{
  className?: string
}> = ({ className }) => {
  const defaultIsMailingListPanelOpen = !!useQueryParam('showExistingMailingList')
  const [isMailingListsPanelOpen, setIsMailingListsPanelOpen] = useState<boolean>(defaultIsMailingListPanelOpen)
  const [selectedId, setSelectedId] = useState<string | null>()
  const navigateToReview = useNavigateToReview()
  const modalCloseLabel = useMsgModalClose()
  const completedListsLabel = useMsgCompletedListsLabel()
  const inProgressLabel = useMsgInProgressLabel()
  const isPostcard = usePostcardPRDCheck()
  const mailingListLabel = useMsgMailingListLabel()
  const fireTracking = useEventTracking()
  const intl = useIntl()
  const isSignedIn = useIsSignedIn()
  const { setUploadPageStatus: setPageStatus } = useUploadPageStatus()
  const { manualAddressingFeatureToggle, loadingManualAddressingFlag } = useFeatureFlagContext()
  const { logError } = useLogger()

  const {
    data: inProgressAddressList,
    isLoading: isLoadingInProgressList,
    isSuccess: isSuccessInProgressList,
    isError: isErrorInProgressList,
  } = useAddressListInProgress()
  const {
    data: mailingLists,
    isIdle: isIdleMailingLists,
    isLoading: isLoadingMailingLists,
    isSuccess: isSuccessMailingLists,
    isError: isErrorMailingLists,
  } = useMailingListAll()
  const trackingProductPageName = useTrackingProductPageName(PageName.UPLOAD_PAGE)
  const signIn = useSignIn(trackingProductPageName, [{ key: 'showExistingMailingList', value: 'true' }])

  const canPanelOpen = useMemo(() => !!mailingLists?.length || !!inProgressAddressList?.requestId, [inProgressAddressList?.requestId, mailingLists?.length])

  const unknownErrorToast = useToastSomethingWentWrong()
  const {
    mutationResult: { isError: isErrorAddToCart },
  } = useAddToCart()

  let flyOutTrackingData: AdditionalTrackingDataType = {
    category: TrackingCategoryType.FLY_OUT,
    label: TrackingLabelType.FLY_OUT_SELECTION,
    pageName: trackingProductPageName,
  }

  useEffect(() => {
    if (
      isMailingListsPanelOpen &&
      (isErrorMailingLists || (isSuccessMailingLists && !mailingLists?.length)) &&
      (isErrorInProgressList || (isSuccessInProgressList && !inProgressAddressList?.requestId))
    ) {
      setPageStatus(UploadPageStatus.ERROR_NO_MAILING_LIST)
      newRelicNoticeError('Error in fetching existing mailing list')
    }
  }, [
    isMailingListsPanelOpen,
    isErrorMailingLists,
    isSuccessMailingLists,
    mailingLists?.length,
    isErrorInProgressList,
    isSuccessInProgressList,
    inProgressAddressList?.requestId,
    setPageStatus,
    inProgressAddressList,
    mailingLists,
  ])

  useEffect(() => {
    if (inProgressAddressList?.requestId) {
      setSelectedId(inProgressAddressList.requestId)
    }
  }, [inProgressAddressList?.requestId])
  useEffect(() => {
    if (Array.isArray(mailingLists) && mailingLists.length && !inProgressAddressList?.requestId) {
      setSelectedId(mailingLists[0].mailingListId)
    }
  }, [inProgressAddressList?.requestId, mailingLists])

  useEffect(() => {
    if (defaultIsMailingListPanelOpen) {
      setIsMailingListsPanelOpen(true)
    }
  }, [defaultIsMailingListPanelOpen])

  useEffect(() => {
    if (isErrorAddToCart) {
      unknownErrorToast()
      logError('Error occurred while adding to cart', { contextData: { error: isErrorAddToCart } })
    }
  }, [isErrorAddToCart, unknownErrorToast, logError])

  const viewMailingLists = () => {
    fireTracking(TrackingEventType.FLY_OUT_VIEWED, flyOutTrackingData)
    setIsMailingListsPanelOpen(true)
  }

  const closeMailingListPopup = () => {
    fireTracking(TrackingEventType.FLY_OUT_CLOSED, flyOutTrackingData)
    setIsMailingListsPanelOpen(false)
  }

  const onNext = () => {
    if (!selectedId) return undefined
    let inProgressName: string | undefined = undefined
    let completedName: string | undefined = undefined
    if (inProgressAddressList && selectedId === inProgressAddressList.requestId) {
      inProgressName = inProgressAddressList.originalFileName
    }
    const fromMailingList: MailingListEntity | undefined = mailingLists?.find(mailingList => selectedId === mailingList.mailingListId)
    if (fromMailingList) {
      completedName = fromMailingList.name
    }
    if (inProgressName || completedName) {
      flyOutTrackingData = {
        ...flyOutTrackingData,
        eventDetail: inProgressName || completedName,
      }
      fireTracking(TrackingEventType.FLY_OUT_CLICKED, flyOutTrackingData)
    }

    if (inProgressName) {
      navigateToReview(selectedId, 'UNKNOWN')
    } else if (completedName) {
      navigateToReview(selectedId, 'UPLOADED')
    }
  }
  return (
    <>
      {isSignedIn === false ? (
        <Button
          data-section="Mailing List Page:Link Clicked"
          data-position="2"
          data-translation="Sign In"
          mt={3}
          specialVariant="design-path"
          className={classNames(className)}
          onClick={signIn}
        >
          {isPostcard ? (
            <FormattedMessage defaultMessage="Sign in to use a saved mailing list" description="Button label to sign in to use a saved mailing list" />
          ) : (
            <FormattedMessage defaultMessage="Sign in to use a saved list" description="Button label to sign in to select a saved list" />
          )}
        </Button>
      ) : (
        <Button
          data-section="Mailing List Page:Link Clicked"
          data-position="2"
          data-translation="Use a saved mailing list"
          disabled={isSignedIn === null || isIdleMailingLists || isLoadingMailingLists || isLoadingInProgressList}
          mt={3}
          specialVariant="design-path"
          className={classNames(className)}
          onClick={viewMailingLists}
          iconPosition="right"
        >
          {!loadingManualAddressingFlag &&
            (isPostcard ? (
              <FormattedMessage defaultMessage="Use a saved mailing list" description="Button label to use a saved mailing list" />
            ) : manualAddressingFeatureToggle ? (
              <FormattedMessage defaultMessage="Use a saved recipient list" description="Button label to use a saved recipient list" />
            ) : (
              <FormattedMessage defaultMessage="Use a saved address list" description="Button label to use a saved address list" />
            ))}

          {!isIdleMailingLists && !isLoadingMailingLists && !isLoadingInProgressList && <Icon iconType="arrowRight" size="32p" skin="standard" />}
          {(isSignedIn === null || isIdleMailingLists || isLoadingMailingLists || isLoadingInProgressList) && (
            <Box className={classes.loading}>
              <Spinner
                size="tiny"
                accessibleText={intl.formatMessage({
                  defaultMessage: 'Add mailing list to cart in progress',
                  description: 'Label for Adding mailing list to cart',
                })}
              />
            </Box>
          )}
        </Button>
      )}
      <ModalDialog variant="panel-right" bodyWidth="capped" isOpen={canPanelOpen && isMailingListsPanelOpen} onRequestDismiss={closeMailingListPopup}>
        <ModalDialogContent pt={10} aria-label={mailingListLabel}>
          <ModalDialogCloseButton visuallyHiddenLabel={modalCloseLabel} />
          <Box className={classes.container}>
            <ModalDialogBody px={2}>
              <H2 fontSize={'x2large'} mb={6}>
                {isPostcard ? (
                  <FormattedMessage
                    defaultMessage="Which mailing list would you like to use?"
                    description="Heading for the list of lists from which the user can choose"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Which list would you like to use?"
                    description="Heading for the list of lists from which the user can choose"
                  />
                )}
              </H2>
              {(isLoadingMailingLists || isLoadingInProgressList) && (
                <FlexBox justifyContent="center">
                  <Spinner
                    mt={6}
                    accessibleText={intl.formatMessage({
                      defaultMessage: 'Mailing list selector in progress',
                      description: 'Label for selecting the mailing list',
                    })}
                  />
                </FlexBox>
              )}
              <SelectionSet variant="single-select" selectedValue={selectedId} onSelectedValueChange={setSelectedId}>
                {!!inProgressAddressList?.requestId && (
                  <ListSelectionSection
                    sectionHeading={inProgressLabel}
                    list={[
                      {
                        id: inProgressAddressList?.requestId,
                        lastUpdatedOn: inProgressAddressList?.processingTimeStamp,
                        recipientsCount:
                          (inProgressAddressList?.validAddresses || []).length +
                          (inProgressAddressList?.duplicatedAddressGroups || []).reduce((agg, u) => agg + u.addresses.length, 0) +
                          (inProgressAddressList?.invalidAddresses || []).length,
                        title: inProgressAddressList?.originalFileName,
                      },
                    ]}
                  />
                )}
                {(isErrorMailingLists || (isSuccessMailingLists && !mailingLists?.length)) &&
                  (isErrorInProgressList || (isSuccessInProgressList && !inProgressAddressList?.requestId)) && (
                    // Ideally, this will never appear as the side panel will never open in this case.
                    <Typography mt={4} textColor="holiday">
                      {!loadingManualAddressingFlag &&
                        (isPostcard ? (
                          <FormattedMessage
                            defaultMessage="You don’t have any saved mailing lists yet. Upload a mailing list and we’ll save it to your account to use later."
                            description="This error appears when there is no saved mailing list"
                          />
                        ) : manualAddressingFeatureToggle ? (
                          <FormattedMessage
                            defaultMessage="You don’t have any saved recipient lists yet. Upload an recipient list and we’ll save it to your account to use later."
                            description="This error appears when there is no saved recipient list"
                          />
                        ) : (
                          <FormattedMessage
                            defaultMessage="You don’t have any saved address lists yet. Upload an address list and we’ll save it to your account to use later."
                            description="This error appears when there is no saved address list"
                          />
                        ))}
                    </Typography>
                  )}
                {isErrorMailingLists && !loadingManualAddressingFlag && (
                  <Typography mt={4} textColor="error">
                    {isPostcard ? (
                      <FormattedMessage
                        defaultMessage="Couldn't get your completed mailing lists. Seems like our server is acting up. Please try again or report to us."
                        description="This error appears when there is some network/server issue"
                      />
                    ) : manualAddressingFeatureToggle ? (
                      <FormattedMessage
                        defaultMessage="Couldn't get your completed recipient lists. Seems like our server is acting up. Please try again or report to us."
                        description="This error appears when there is some network/server issue"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Couldn't get your completed address lists. Seems like our server is acting up. Please try again or report to us."
                        description="This error appears when there is some network/server issue"
                      />
                    )}
                  </Typography>
                )}
                {Array.isArray(mailingLists) && mailingLists.length > 0 && (
                  <ListSelectionSection
                    sectionHeading={inProgressAddressList?.requestId ? completedListsLabel : ''}
                    list={mailingLists.map(u => ({
                      id: u.mailingListId,
                      lastUpdatedOn: u.lastUpdatedOn,
                      recipientsCount: u.addressIds.length,
                      title: u.name,
                    }))}
                  />
                )}
              </SelectionSet>
            </ModalDialogBody>
          </Box>
          <ModalDialogFooter pinned>
            <Box className={classes.container}>
              <Button
                data-section="Mailing List Page:Link Clicked"
                data-position="0"
                data-translation="Fly-Out Clicked:Next"
                skin="primary"
                m={0}
                width="full-width"
                disabled={!selectedId}
                onClick={onNext}
              >
                {isPostcard ? (
                  <FormattedMessage defaultMessage="Next" description="Button label for next page navigation" />
                ) : (
                  <FormattedMessage defaultMessage="Select" description="Button label for next page navigation" />
                )}
              </Button>
            </Box>
          </ModalDialogFooter>
        </ModalDialogContent>
      </ModalDialog>
    </>
  )
}

export const BtnMailingList = withErrorBoundary(BtnMailingListInternal)
